<template>
  <div>
    <nav-bar />
    <div class="dialogWrapper">
      <div v-if="this.response != 200" class="dialogWindow">
        <h2>Promena lozinke</h2>
        <p>Unesite email adresu na koju ćemo Vam poslati link za promenu lozinke.</p>
        <v-form ref="form" lazy-validation>
          <v-text-field
            @keydown.space.prevent
            v-model="email"
            color="#137547"
            outlined
            rounded
            label="Vaš email"
            placeholder="Unesite email"
            type="text"
            class="vtxt"
            :rules="emailRules"
            required
          ></v-text-field>
          <p class="emailError">{{ errorMessage }}</p>
          <button class="button" @click.prevent="submitForm">Pošalji novu lozinku</button>
        </v-form>
      </div>
      <div v-else-if="this.response === 200" class="dialogWindow">
        <v-card-text> Proverite da li Vam je stigao mail za promenu lozinke. </v-card-text>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../Components/NavBar.vue";
import Footer from "../Components/Footer.vue";

export default {
  components: {
    NavBar,
    Footer,
  },
  name: "ForgottenPassword",
  data() {
    return {
      email: "",
      response: 0,
      errorMessage: "",
      emailRules: [
        (v) => !!v || "Email je obavezan",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "Unesite email u ispravnom formatu",
      ],
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        let email = {
          email: this.email,
        };
        await axios
          .post("ForgottenPassword", email)
          .then((response) => {
            this.response = response.status;
          })
          .catch(() => {
            this.errorMessage = "Ne postoji korisnik sa unetim emailom.";
            setTimeout(() => (this.errorMessage = ""), 3000);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogWindow {
  width: 600px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin: 1rem;
  padding: 2rem 1rem;
}

.emailError {
  color: red;
  text-align: center;
}

.vtxt {
  width: 100%;
}

.button {
  width: 100%;
  padding: 0.8rem;
  color: #fff;
  background-color: #137547;
  border-radius: 50px;
}
</style>
